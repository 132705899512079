import { AxiosError } from 'axios';
import * as api from '../../api/authApi';
import { endpoints } from '../../config/endpoints';
import httpStatusCodes from '../../constants/http-status-codes';
import { isTokenExpired } from '../../helpers/jwt-helper';
import * as storageHelper from '../../helpers/storage-helper';
import { ErrorCodes } from '../../models/error-code';
import * as actionCreators from '../action-creators/authenticate-action-creators';
import * as commonActionCreators from '../action-creators/common-action-creators';
import { BaseAction } from '../model/action';

const doAuthentication =
  (code: string, successCallback: Function, failureCallback: Function) =>
  async (dispatch: (action: BaseAction) => void) => {
    try {
      dispatch(commonActionCreators.acSetLoadingIndicator(true));
      await api.getAccessToken(code).then((response) => {
        if (response.status !== httpStatusCodes.OK) {
          dispatch(actionCreators.acSetIsAuthenticated(false));
          dispatch(commonActionCreators.acSetLoadingIndicator(false));
          if (failureCallback) {
            failureCallback();
          }
          return;
        }
        const smgToken = response.data;
        storageHelper.setSmgToken(smgToken);
      }).catch(() => {
        dispatch(actionCreators.acSetIsAuthenticated(false));
        dispatch(commonActionCreators.acSetLoadingIndicator(false));
        if (failureCallback) {
          failureCallback();
          return;
        }
      });
      
      const msTeamsToken = storageHelper.getMsSsoToken();
      if (!msTeamsToken) {
        if (failureCallback) {
          failureCallback();
        }
        return;
      }

      await api.authenticate().then((response) => {
        if (response.status === httpStatusCodes.OK) {
          api.updateSmgTokens();
          dispatch(actionCreators.acSetIsAuthenticated(true));
          dispatch(commonActionCreators.acSetLoadingIndicator(false));
          if (successCallback) {
            successCallback();
          }
        }
      }).catch(() => {
        dispatch(commonActionCreators.acSetLoadingIndicator(false));
        dispatch(actionCreators.acSetIsAuthenticated(false));
        if (failureCallback) {
          failureCallback();
        }
        return;
      });
    } catch (error) {
      if ((error as AxiosError).response?.status == 401) {
        dispatch(commonActionCreators.acSetLoadingIndicator(false));
        dispatch(actionCreators.acSetIsAuthenticated(false));
        if (failureCallback) {
          failureCallback();
        }
      }
      return;
    }
  };

const removeAuthentication = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    await api.logout();
    resetSession();
    dispatch(actionCreators.acSetIsAuthenticated(false));

    const loginUrl = (await api.getLoginUrl()).data.loginUrl;
    dispatch(actionCreators.acSetAuthUrl(loginUrl));
  } catch (error) {
    console.error('removeAuthentication', error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const removeSmgAuthentication = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    resetSession();
  } catch (error) {
    console.error('removeSmgAuthentication', error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const checkAuthentication = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));

    const accessToken = storageHelper.getSmgAccessToken();
    const refreshToken = storageHelper.getSmgRefreshToken();
    const msTeamsToken = storageHelper.getMsSsoToken();
    if (!msTeamsToken || !accessToken || !refreshToken || isTokenExpired(refreshToken)) {
      dispatch(actionCreators.acSetIsAuthenticated(false));
      const loginUrl = (await api.getLoginUrl()).data.loginUrl;
      dispatch(actionCreators.acSetAuthUrl(loginUrl));
      return;
    }

    const response = await api.isAuthenticated();
    const isAuthenticated = response.data;
    dispatch(actionCreators.acSetIsAuthenticated(isAuthenticated));

    if (!isAuthenticated) {
      resetSession();
      const loginUrl = (await api.getLoginUrl()).data.loginUrl;
      dispatch(actionCreators.acSetAuthUrl(loginUrl));
      dispatch(actionCreators.acSetIsAuthenticated(false));
    }
  } catch (error) {
    if ((error as AxiosError).response?.status == 400) {
      dispatch(actionCreators.setAuthenticationError(ErrorCodes.errBr400));
    } else if ((error as AxiosError).response?.status == 401) {
      dispatch(actionCreators.setAuthenticationError(ErrorCodes.errBr401));
    } else if ((error as AxiosError).response?.status == 404) {
      dispatch(actionCreators.setAuthenticationError(ErrorCodes.errBr404));
    } else if ((error as AxiosError).response?.status == 500) {
      dispatch(actionCreators.setAuthenticationError(ErrorCodes.errBr500));
    } else {
      dispatch(actionCreators.setAuthenticationError(ErrorCodes.errNetwork));
    }
    
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const resetSession = async () => {
  const refreshToken = storageHelper.getSmgRefreshToken();
  await api.logoutFromSmg(refreshToken);
  storageHelper.removeSmgToken();
  storageHelper.removeMsSsoToken();
};

export { doAuthentication, checkAuthentication, removeAuthentication, removeSmgAuthentication };
